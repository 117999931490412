<template>
  <div id="modalProfile" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Adicionar Simulação</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="closeModal(false)"
          />
        </div>
        <form @submit.prevent="saveSimulation">
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="filterClassification">Classificação:</label>
                <select
                  id="filterClassification"
                  :class="{
                    'is-invalid': v$.simulation.classification.$error,
                  }"
                  v-model="v$.simulation.classification.$model"
                  class="form-select"
                >
                  <option value="" selected disabled>
                    Selecione uma classificação
                  </option>
                  <option
                    v-for="(classification, index) in classifications"
                    :key="index"
                    :value="classification"
                  >
                    {{ classification }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-6 mb-3">
                <label class="form-label">
                  Data: <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data do Lançamento"
                  :class="{
                    'is-invalid': v$.simulation.date.$error,
                  }"
                  v-model="v$.simulation.date.$model"
                />
                <span
                  v-if="v$.simulation.date.required.$invalid"
                  class="invalid-feedback"
                >
                  Campo Obrigatório
                </span>
              </div>
              <div class="col-sm-12 col-md-6 mb-3">
                <label class="form-label">
                  Valor: <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  placeholder="Valor Liquido"
                  :class="{
                    'is-invalid': v$.simulation.netValue.$error,
                  }"
                  v-model="v$.simulation.netValue.$model"
                />
                <span
                  v-if="v$.simulation.netValue.required.$invalid"
                  class="invalid-feedback"
                >
                  Campo Obrigatório
                </span>
                <span
                  v-if="v$.simulation.netValue.decimal.$invalid"
                  class="invalid-feedback"
                >
                  Campo deve ser numerico.
                </span>
              </div>
              <div class="col-sm-12">
                <label class="form-label"> Histórico: </label>
                <textarea
                  class="form-control"
                  placeholder="Descrição"
                  v-model="simulation.historic"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="closeModal(false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Atualizar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Cadastrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, decimal } from '@vuelidate/validators'
import dateNowUs from '@/utils/date'

export default {
  name: 'AddOrEditSimulationModal',
  props: {
    id: {
      type: String,
      default: '',
    },
    company: {
      type: Object,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      modalInstance: null,
      isLoadingClassifications: true,
      classifications: [],
      simulation: {
        classification: '',
        cashAccount: this.company.cashAccount,
        codColigada: this.company.codColigada,
        entryType: 'SIMULAÇÃO',
        createdDate: dateNowUs,
        date: '',
        documentNumber: '',
        originalValue: '',
        netValue: '',
        historic: '',
      },
      disableButton: false,
    }
  },

  methods: {
    async saveSimulation() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch(
          'saveSimulation',
          this.simulation,
        )
        this.$root.$refs.notification.success(response.message)
        this.closeModal(true)
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    closeModal(reload) {
      this.modalInstance.hide()
      this.$emit('closeModal', reload)
    },
    showModal() {
      this.modalInstance = new window.bootstrap.Modal(
        document.getElementById('modalProfile'),
        {
          backdrop: 'static',
          keyboard: false,
        },
      )
      this.modalInstance.show()
    },
    async getClassifications() {
      try {
        this.isLoadingClassifications = true
        this.classifications = await this.$store.dispatch(
          'getClassifications',
          {
            codColigada: this.company.codColigada,
          },
        )
        this.isLoadingClassifications = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getSimulation() {
      try {
        this.isLoadingSimulation = true
        this.simulation = await this.$store.dispatch('getSimulation', {
          id: this.id,
        })
        this.isLoadingSimulation = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  validations() {
    return {
      simulation: {
        date: {
          required,
        },
        netValue: {
          required,
          decimal,
        },
        classification: {
          required,
        },
      },
    }
  },

  mounted() {
    this.getClassifications()
    if (this.id) {
      this.getSimulation()
    }
    this.showModal()
  },
}
</script>
